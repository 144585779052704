import axios from "axios";
import { User } from "../types";
import jwt_decode from "jwt-decode";

const TOKEN_KEY = "token";

const defaultUser: User = {
    email: "",
    isAuthenticated: false,
    token: "",
    nombreCompleto: "",
    urlFoto: "",
}

const setToken =  (token: string) => {localStorage.setItem(TOKEN_KEY, token);}

const getToken = () => ( localStorage.getItem(TOKEN_KEY) || null )

const removeToken = () => {
    localStorage.removeItem(TOKEN_KEY);
}

const removeUserInfo = () => {
    localStorage.removeItem('student');
    localStorage.removeItem('teachers');
    localStorage.removeItem('evaluation');
    localStorage.removeItem('React.LC.KY.AP');
    localStorage.removeItem('React.LC.KY.MS');
}

export const authenticate = (token?: string): User => {
    if (token) {
        setToken(token);
    }

    const _token = token ? token : getToken();

    if (!_token) {
        return { ...defaultUser,}
    }

    const decode: any = jwt_decode(_token);
    const currentTime = Date.now() / 1000;
    if (decode.exp < currentTime) {
        removeToken();
        removeUserInfo();
        return { ...defaultUser };
    }
    
    axios.defaults.headers.common["Authorization"] = `Bearer ${_token}`;
    return { 
        ...defaultUser, email: decode.sub, isAuthenticated: true, token: _token,
    }
}

export const logout = (): User => {
    removeToken();
    removeUserInfo();
    delete axios.defaults.headers.common["Authorization"];
    return { ...defaultUser, isAuthenticated: false};
}