import React, { useState, useEffect } from "react";
import { Route, Routes, Link, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import {
  CssBaseline,
  Toolbar,
  Divider,
  IconButton,
  Container,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Tooltip,
  Avatar,
  Menu,
  MenuItem,
  Stack,
  Grid,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import HomeIcon from "@mui/icons-material/Home";
import getRouter from "../../routes";
import MuiAppBar from "@mui/material/AppBar";
import { Box } from "@mui/system";
import Copyright from "../../components/general/Copyright";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouseUser,
  faSignOutAlt,
  faGripHorizontal,
} from "@fortawesome/free-solid-svg-icons";
import { logout } from "../../utils/auth";
import { SC_KEY } from "../../utils/constants";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AppSAM from "../../components/AppSAM";
import { Logo, getPrimaryColorHEX } from "../../config";
import { useAuthState } from "../../context/authContext";

const settings = [
  {
    name: "Inicio",
    url: "/home/home",
    icon: <FontAwesomeIcon icon={faHouseUser} />,
  },
  {
    name: "Cerrar sesión",
    url: "",
    icon: <FontAwesomeIcon icon={faSignOutAlt} />,
  },
];

const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

function Dashboard() {
  let modeLs;

  if (localStorage.getItem("mode")) {
    modeLs = localStorage.getItem("mode");
  } else {
    modeLs = "light";
    localStorage.setItem("mode", modeLs);
  }

  const [mode, setMode] = React.useState(modeLs);

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          neutral: {
            main: "#000",
          },
          primary: {
            main: mode === "dark" ? "#98348B" : "#293881",
          },
        },
      }),
    [mode],
    localStorage.setItem("mode", mode)
  );

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [marca, setmarca] = useState("")
  const router = getRouter();
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorApps, setAnchorApps] = React.useState(null);
  var CryptoJS = require("crypto-js");

  const user = useAuthState()

  const domain = user.email.split("@")[1];

  var bytesAP = CryptoJS.AES.decrypt(
    localStorage.getItem("React.LC.KY.AP"),
    SC_KEY
  );
  var userInformationAP = JSON.parse(bytesAP.toString(CryptoJS.enc.Utf8));

  const nameUser = userInformationAP.nombreCompleto;
  const imageProfile = userInformationAP.urlFoto;

  const goHome = () => {
    navigate("/home/home");
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenApps = (event) => {
    setAnchorApps(event.currentTarget);
  };

  const handleCloseApps = () => {
    setAnchorApps(null);
  };

  const handleLogOut = () => {
    logout();
    setAnchorElUser(null);
  };

  useEffect(() => {
    var aux = user.email.split("@")[1]
    setmarca(aux.split(".")[0])
  }, [user])
  
  console.log("/////////////////////////////////////////////////")
  console.log(router)
  console.log(user)
  console.log("/////////////////////////////////////////////////")

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar position="absolute" open={open}>
            <Toolbar
              sx={{
                pr: "24px",
                backgroundColor: marca !== "" ? getPrimaryColorHEX(marca) : "#233878",
                minHeight: "50px !important", // keep right padding when drawer closed
              }}
            >
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={goHome}
              >
                <HomeIcon />
              </IconButton>

              {!open && marca !== "" ? (
                <Logo
                  sx={{ width: "90px", height: "45px",cursor:'pointer' }}
                  Marca={marca}
                  Type="bt"
                  Size="700350"
                  onClick={goHome}
                />
              ) : null}
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                textAlign="center"
              >
                {router?.title}
              </Typography>

              <Box sx={{ flexGrow: 0, marginLeft: 1 }}>
                <Tooltip title="Otros Aplicativos" enterTouchDelay={0}>
                  <IconButton color="inherit" onClick={handleOpenApps}>
                    <FontAwesomeIcon
                      icon={faGripHorizontal}
                      style={{ height: "20px" }}
                    />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "52px", ml: "70px" }}
                  id="menu-appbar"
                  anchorEl={anchorApps}
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  keepMounted
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                  open={Boolean(anchorApps)}
                  onClose={handleCloseApps}
                >
                  <Grid
                    container
                    sx={{ height: "100%", width: 310, padding: 1 }}
                  >
                    {userInformationAP.scopes.map((scopeAp, idx) => {
                      return (
                        <AppSAM
                          key={idx}
                          scope={{
                            scopeid: scopeAp.scopeid,
                            scopedetails: scopeAp.scopedetails,
                            scopename: scopeAp.scopename,
                            scopeicon: scopeAp.scopeicon,
                            scopecolor: scopeAp.scopecolor,
                            iconpath: scopeAp.iconpath,
                            scopepath: scopeAp.scopepath,
                            isapp: scopeAp.isapp,
                          }}
                        ></AppSAM>
                      );
                    })}
                  </Grid>
                </Menu>
              </Box>
              <Box sx={{ flexGrow: 0, marginLeft: 1 }}>
                <Tooltip key={1} title="Más opciones" enterTouchDelay={0}>
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt={nameUser} src={imageProfile} />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "52px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  keepMounted
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <Box sx={{ display: "flex" }}>
                    <MenuItem key="user-info" onClick={handleCloseUserMenu}>
                      <Stack>
                        <Typography
                          sx={{ fontStyle: "italic", fontWeight: "bold" }}
                          textAlign="left"
                        >
                          {nameUser}
                        </Typography>
                        <Typography sx={{ fontSize: 11 }} textAlign="left">
                          {userInformationAP.correo}
                        </Typography>
                      </Stack>
                    </MenuItem>

                    <IconButton
                      sx={{ mr: 1 }}
                      onClick={colorMode.toggleColorMode}
                      color="inherit"
                    >
                      {theme.palette.mode === "dark" ? (
                        <Brightness7Icon />
                      ) : (
                        <Brightness4Icon />
                      )}
                    </IconButton>
                  </Box>
                  <Divider />
                  {settings.map((setting, id) => {
                    if (setting.name !== "Cerrar sesión") {
                      return (
                        <MenuItem
                          key={setting.name}
                          onClick={handleCloseUserMenu}
                          sx={{ marginLeft: "auto" }}
                          component={Link}
                          to={setting.url}
                        >
                          <Typography
                            sx={{ marginLeft: "auto", marginRight: 1 }}
                          >
                            {setting.name}
                          </Typography>
                          {setting.icon}
                        </MenuItem>
                      );
                    } else {
                      return (
                        <a
                          key={id}
                          href={`https://sam.${domain}/`}
                          style={{ color: "inherit", textDecoration: "none" }}
                        >
                          <MenuItem
                            onClick={handleLogOut}
                            sx={{ marginLeft: "auto" }}
                          >
                            <Typography
                              sx={{ marginLeft: "auto", marginRight: 1 }}
                            >
                              {setting.name}{" "}
                            </Typography>{" "}
                            {setting.icon}
                          </MenuItem>
                        </a>
                      );
                    }
                  })}
                </Menu>
              </Box>
            </Toolbar>
          </AppBar>
          <Drawer variant="permanent" open={open}>
            
            <Toolbar
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                px: [1],
                minHeight: "50px !important",
              }}
            >
              {mode === "light" ? (
                <img
                  src="https://resoures-educem.s3.us-west-2.amazonaws.com/Logos/Educem/700350cb.png"
                  alt=""
                  width="90"
                  height="45"
                />
              ) : (
                <img
                  src="https://resoures-educem.s3.us-west-2.amazonaws.com/Logos/Educem/700350bt.png"
                  alt=""
                  width="90"
                  height="45"
                />
              )}
            </Toolbar>

            <Divider />
            <br />
            <>
              {router?.screens.map((item, idx) => {
                if (item.name) {
                  return (
                    <div
                      key={idx}
                      style={{
                        padding: "5px 0px",
                        boxSizing: "border-box",
                        margin: "0px 5px",
                      }}
                    >
                      <ListItem
                        key={`${idx}`}
                        style={{ height: "32px", padding: open ? 14 : 6 }}
                      >
                        <ListItemButton
                          component={Link}
                          to={item?.url}
                          sx={{
                            minHeight: 30,
                            justifyContent: open ? "initial" : "center",
                            px: open ? 2.7 : null,
                            paddingRight: -10,
                          }}
                        >
                          <ListItemIcon
                            sx={
                              open ? { minWidth: "40px" } : { minWidth: "10px" }
                            }
                          >
                            {item.icon}
                          </ListItemIcon>
                          {open ? <ListItemText primary={item.name} /> : null}
                        </ListItemButton>
                      </ListItem>
                    </div>
                  );
                }
                return null;
              })}
            </>
          </Drawer>
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Toolbar />
            
            <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
              <React.Suspense fallback={""}>
                <Routes>
                  {router?.screens.map((item, idx) => {
                    return (
                      <Route
                        key={`component${idx}`}
                        path={item?.url}
                        element={<item.component />}
                      />
                    );
                  })}
                </Routes>
              </React.Suspense>
            </Container>
          
          </Box>
        </Box>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default Dashboard;

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(5),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(6.5),
      },
    }),
  },
}));
