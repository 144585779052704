import { API_URL } from "../../constants";

export const GET_STUDENT_INFO_BY_EMAIL_ENDPOINT  = (email: string) => `${API_URL}/student/byemail/${email}`;
export const GET_ALL_EVALUATION = (alumnoid: any) => `${API_URL}/evaluation/${alumnoid}`;
export const GET_TEACHERS_BY_GROUP = (id:any, idAlumno: any, idEvaluacion: any) =>  `${API_URL}/teacherproyectionassignature/${id}/${idAlumno}/${idEvaluacion}`;
export const GET_ALERT_WORDS = () => `${API_URL}/alertwords/`;
export const POST_SAVE_EVALUATION = `${API_URL}/evaluationresult/`;
export const POST_SAVE_COMMENT = `${API_URL}/comment/`;
export const POST_SAVE_PDF = `${API_URL}/pdf/`;
export const POST_EVALUATE_TEACHER = `${API_URL}/evaluationresult/saveresults`

