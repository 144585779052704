import {createContext} from "react";

export const AnswersContext = createContext<any[]>([]);

export const TeacherContext = createContext<any[]>([]);

export const TeachersContext = createContext<any[]>([]);

export const StudentContext = createContext<any[]>([]);

export const EvaluationContext = createContext<any[]>([]);

export const EvaluatedTeachers = createContext<any[]>([]);

export const TeacherAssignatures = createContext<any[]>([]);
