import axios from "axios";
import { GET_COSNTACIA, GET_MARCA } from "../../utils/endpoints/sai/SaiEndpoint";

export const getConstancia = (
    folio: number,matricula:string
    ) => {
    return axios.get(GET_COSNTACIA(folio,matricula));
}

export const getMarca = (
    folio: number,
    ) => {
    return axios.get(GET_MARCA(folio));
}