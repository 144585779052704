import { useQuery } from "react-query";
import { getConstancia } from "../../services/Sai/SaiServices";
import { useState } from "react";
import { FIRE_URL } from "../../utils/constants";
import { Button, Card, CardContent, CircularProgress, Container, Divider, Grid, TextField, Typography } from "@mui/material";
import ConstanciaView from "./ConstanciaView";
import BuscadorConstancia from "./BuscadorConstancia";

interface Props {
    folio: any
    setFolio: any
    matricula: any
    setMatricula: any
    setAlert?: any
    setOpenAlert?: any
    enabled?: any
    setEnabled?: any

    search?: any
    path?: any
    setOpen?: any
    marca?: any
    setMarca?: any
    porqr?: any
    setPorqr?: any

}


function Constancia({ folio, setFolio,
    matricula, setMatricula,
    setAlert, setOpenAlert, enabled, setEnabled,
    search, path,
    setOpen,
    marca, setMarca,
    porqr,setPorqr
}: Props) {
    const [consta, setConsta] = useState<null | any>(null);
    const [valida, setValida] = useState(false);
    const [error, setError] = useState<null | any>(null);
    const { isLoading, isFetching, isError } = useQuery(["getConstancia"], () =>
        getConstancia(folio, matricula), {
        enabled: enabled,
        onSuccess(data) {
        

            if (data.data) {                
                setConsta(data.data)
                if (data.data.marca_educativa) {
                    if (data.data.marca_educativa.includes("FIRENZE")) {
                    }
                }
                if (data.data.marca_educativa.includes("FIRENZE")) {
                    
                    setMarca("institutofirenze")
                } else {
                    setMarca("educem")
                }

                
            } else {
                console.log("no hay we")
                setError("Ingresa folio y matricula validos")
            }
            
            setOpen(false)
            setEnabled(false)
            setPorqr(true)
        },
        onError(err: any) {
            console.log("err/////////////////")
            console.log(err)
            setAlert({ ...alert, severity: 'error', message: 'Error al obtener Periodo de interes' });
            setOpenAlert(true)
        }
    });

    
    const handleChange = (event: any) => {
        setFolio(event.target.value)
    };

    const searchButton = (event: any) => {
        matricula === consta.matricula ? setValida(true) : setValida(false)
    };

    return (

        <>
        {!porqr?null:
            isLoading ? <><CircularProgress /></> :
                <>
                    {consta ?
                        <ConstanciaView datos={consta} marca={marca}></ConstanciaView>
                        :
                        <>
                            <BuscadorConstancia matricula={matricula} setMatricula={setMatricula}
                                folio={folio} setFolio={setFolio} setEnable={setEnabled}
                                setOpen={setOpen}
                                marca={marca} setMarca={setMarca}
                                error={error} setError={setError}
                            ></BuscadorConstancia>
                        </>
                    }


                </>}



        </>


    );
}


export default Constancia;