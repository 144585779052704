import { Button, Card, CardActions, CardContent, Container, Divider, Grid, TextField, Typography } from "@mui/material";
import { useState } from "react";

interface Props {

    search?: any
    path?: any
    setOpen?: any
    folio?: any
    setFolio?: any
    matricula?: any
    setMatricula?: any
    setEnable?: any

    marca?: any
    setMarca?: any
    error?: any
    setError?: any
}

function BuscadorConstancia({
    search, path, setOpen,
    folio, setFolio,
    matricula, setMatricula,
    setEnable,
    marca, setMarca,
    error, setError

}: Props) {
    const [foliotext, setFoliotext] = useState<null | number>(folio ? folio : "");

    const [matriculatext, setMatriculatext] = useState<null | number>(matricula ? matricula : "");

    const handleEnterKeyPress = (e: any) => {
        if (e.key === 'Enter') {
            searchButton(null);
        }
    };

    const searchButton = (event: any) => {
        setFolio(foliotext ? foliotext : 0)
        setMatricula(matriculatext ? matriculatext : "")
        setError(null)
        setOpen(true)
        setEnable(true)
    };

    return (
        <Grid container
            style={{
                background: `url('../img/${marca}.png') no-repeat center center fixed`,
                backgroundSize: "100% 100%", // Ajustar al ancho y alto de la pantalla
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Card
                sx={{
                    width: "90%", // Ancho del 90% en dispositivos móviles
                    maxWidth: "450px", // Ancho máximo de 450px en computadoras
                    background: "rgba(255, 255, 255, 0.7)",
                    backdropFilter: "blur(8px)",

                }}
            >
                <CardContent sx={{ padding: 3 }}>
                    <Typography gutterBottom variant="h6" component="div" sx={{ wordBreak: "break-word" }}  >
                        Módulo de verificación de documentos digitales
                    </Typography>
                    <TextField

                        label="FOLIO"
                        variant="outlined"
                        sx={{ width: "100%", marginBottom: 2 }}
                        value={foliotext}
                        onChange={(e: any) => setFoliotext(e.target.value)}
                        onKeyPress={handleEnterKeyPress}
                        type="number"

                        error={error}
                    ></TextField>

                    <TextField
                        label="MATRICULA" variant="outlined"

                        sx={{ width: "100%" }}
                        value={matriculatext}
                        onChange={(e: any) => setMatriculatext(e.target.value)}
                        onKeyPress={handleEnterKeyPress}
                        type="text"
                        error={error ? true : false}
                        helperText={error}
                    ></TextField>




                </CardContent>
                <CardActions>

                    <Button
                        sx={{ width: "100%" }}
                        onClick={searchButton}
                        variant="contained">
                        VERIFICAR
                    </Button>
                </CardActions>

            </Card>

        </Grid>
    );
}
export default BuscadorConstancia;