import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import React from 'react'
import './App.css';
import MasterLogin from './pages/Login/MasterLogin';
import { useAuthState } from './context/authContext';
import RenderAppFetchData from './RenderAppFetchData';
import Public from './pages/Public';
import Page404 from './pages/PageNotFound';
import Dashboard from './pages/dashboard';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

function App() {

  const usr = useAuthState();
  return (
    (!usr.token) ?
      <BrowserRouter>
        <Routes>
          {/* <Route path="/masterlogin/" element={<MasterLogin />}></Route>
          <Route path="/home/*" element={<Dashboard />}></Route>
          <Route path="/evaluations/*" element={<Dashboard />}></Route> */}
          <Route path="/" element={<Navigate path="/" to="/ASiS/Validar.aspx" />}> </Route>
          {/* <Route path="/home" element={<Navigate path="/" to="/home/home" />}> </Route> */}
          {/* <Route path="/public" element={<Public />}> </Route> */}
          <Route path="/ASiS/Validar.aspx" element={<Public />}> </Route>
          <Route path="/*" element={<Page404 />}> </Route>
        </Routes>
      </BrowserRouter>
      :
      <RenderAppFetchData></RenderAppFetchData>

  );
}

export default App;

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

