export const Marca: any = 'Virtual';


export const Proveedor: any = {
    Aws: 'https://resoures-educem.s3.us-west-2.amazonaws.com/'
}



export const Organizations: any = {
    Avantthya: {
        Img: {
            Logo: {
                500500: {
                    cb: 'Logos/Avantthya/500500cb.png',
                    ct: 'Logos/Avantthya/500500ct.png',
                    bc1: 'Logos/Avantthya/500500bc1.png',
                    bt: 'Logos/Avantthya/500500bt.png'
                },
                700350: {
                    cb: 'Logos/Avantthya/700350cb.png',
                    ct: 'Logos/Avantthya/700350ct.png',
                    bc1: 'Logos/Avantthya/700350bc1.png',
                    bt: 'Logos/Avantthya/700350bt.png'
                },
                favicon: 'Logos/Avantthya/favicon.png',
            },
            Variante: {
                1: ''
            }
        },
        Color: {
            Primary: {
                HEX: '#001933',
                R: 0,
                G: 25,
                B: 51
            },
            Secondary: {
                HEX: '#23398f',
                R: 232,
                G: 0,
                B: 0
            },
            Info: {
                HEX: '#00897b',
                R: 47,
                G: 147,
                B: 36
            }
        }
    },
    Educem: {
        Img: {
            Logo: {
                500500: {
                    cb: 'Logos/Educem/500500cb.png',
                    ct: 'Logos/Educem/500500ct.png',
                    bc1: 'Logos/Educem/500500bc1.png',
                    bt: 'Logos/Educem/500500bt.png'
                },
                700350: {
                    cb: 'Logos/Educem/700350cb.png',
                    ct: 'Logos/Educem/700350ct.png',
                    bc1: 'Logos/Educem/700350bc1.png',
                    bt: 'Logos/Educem/700350bt.png'
                },
                favicon: 'Logos/Educem/favicon.png',
            },
            Variante: {
                1: ''
            }
        },
        Color: {
            Primary: {
                HEX: '#233878',
                R: 35,
                G: 56,
                B: 120
            },
            Secondary: {
                HEX: '#98348B',
                R: 150,
                G: 51,
                B: 137
            },
            Info: {
                HEX: '#06999D',
                R: 6,
                G: 153,
                B: 157
            }
        }
    },
    FirenzeB: {
        Img: {
            Logo: {
                500500: {
                    cb: 'Logos/Firenze_Basica/500500cb.png',
                    ct: 'Logos/Firenze_Basica/500500ct.png',
                    bc1: 'Logos/Firenze_Basica/500500bc1.png',
                    bt: 'Logos/Firenze_Basica/500500bt.png'
                },
                700350: {
                    cb: 'Logos/Firenze_Basica/700350cb.png',
                    ct: 'Logos/Firenze_Basica/700350ct.png',
                    bc1: 'Logos/Firenze_Basica/700350bc1.png',
                    bt: 'Logos/Firenze_Basica/700350bt.png'
                },
                favicon: 'Logos/Firenze_Basica/favicon.png',
            },
            Variante: {
                1: ''
            }
        },
        Color: {
            Primary: {
                HEX: '#312782',
                R: 49,
                G: 39,
                B: 30
            },
            Secondary: {
                HEX: '#E5007E',
                R: 229,
                G: 0,
                B: 126
            },
            Info: {
                HEX: '#008ad1',
                R: 0,
                G: 138,
                B: 209
            }
        }
    },
    FirenzeS: {
        Img: {
            Logo: {
                500500: {
                    cb: 'Logos/Firenze_Superior/500500cb.png',
                    ct: 'Logos/Firenze_Superior/500500ct.png',
                    bc1: 'Logos/Firenze_Superior/500500bc1.png',
                    bt: 'Logos/Firenze_Superior/500500bt.png'
                },
                700350: {
                    cb: 'Logos/Firenze_Superior/700350cb.png',
                    ct: 'Logos/Firenze_Superior/700350ct.png',
                    bc1: 'Logos/Firenze_Superior/700350bc1.png',
                    bt: 'Logos/Firenze_Superior/700350bt.png'
                },
                favicon: 'Logos/Firenze_Superior/favicon.png',
            },
            Variante: {
                1: ''
            }
        },
        Color: {
            Primary: {
                HEX: '#002B64',
                R: 0,
                G: 43,
                B: 100
            },
            Secondary: {
                HEX: '#007DB5',
                R: 0,
                G: 125,
                B: 181
            },
            Info: {
                HEX: '#58BCA9',
                R: 88,
                G: 188,
                B: 169
            }
        }
    },
    Inice: {
        Img: {
            Logo: {
                500500: {
                    cb: 'Logos/Inice/500500cb.png',
                    ct: 'Logos/Inice/500500ct.png',
                    bc1: 'Logos/Inice/500500bc1.png',
                    bt: 'Logos/Inice/500500bt.png'
                },
                700350: {
                    cb: 'Logos/Inice/700350cb.png',
                    ct: 'Logos/Inice/700350ct.png',
                    bc1: 'Logos/Inice/700350bc1.png',
                    bt: 'Logos/Inice/700350bt.png'
                },
                favicon: 'Logos/Inice/favicon.png',
            },
            Variante: {
                1: ''
            }
        },
        Color: {
            Primary: {
                HEX: '#850F5B',
                R: 133,
                G: 15,
                B: 91
            },
            Secondary: {
                HEX: '#FF4D89',
                R: 255,
                G: 77,
                B: 137
            },
            Info: {
                HEX: '#4A72B2',
                R: 74,
                G: 114,
                B: 178
            }
        }
    },
    Ive: {
        Img: {
            Logo: {
                500500: {
                    cb: 'Logos/Ive/500500cb.png',
                    ct: 'Logos/Ive/500500ct.png',
                    bc1: 'Logos/Ive/500500bc1.png',
                    bt: 'Logos/Ive/500500bt.png'
                },
                700350: {
                    cb: 'Logos/Ive/700350cb.png',
                    ct: 'Logos/Ive/700350ct.png',
                    bc1: 'Logos/Ive/700350bc1.png',
                    bt: 'Logos/Ive/700350bt.png'
                },
                favicon: 'Logos/Ive/favicon.png',
            },
            Variante: {
                1: ''
            }
        },
        Color: {
            Primary: {
                HEX: '#651080',
                R: 101,
                G: 16,
                B: 128
            },
            Secondary: {
                HEX: '#2B1981',
                R: 43,
                G: 25,
                B: 129
            },
            Info: {
                HEX: '#23398F',
                R: 35,
                G: 57,
                B: 143
            }
        }
    },
    Tecmas: {
        Img: {
            Logo: {
                500500: {
                    cb: 'Logos/Tecmas/500500cb.png',
                    ct: 'Logos/Tecmas/500500ct.png',
                    bc1: 'Logos/Tecmas/500500bc1.png',
                    bt: 'Logos/Tecmas/500500bt.png'
                },
                700350: {
                    cb: 'Logos/Tecmas/700350cb.png',
                    ct: 'Logos/Tecmas/700350ct.png',
                    bc1: 'Logos/Tecmas/700350bc1.png',
                    bt: 'Logos/Tecmas/700350bt.png'
                },
                favicon: 'Logos/Tecmas/favicon.png',
            },
            Variante: {
                1: ''
            }
        },
        Color: {
            Primary: {
                HEX: '#002B64',
                R: 0,
                G: 43,
                B: 100
            },
            Secondary: {
                HEX: '#186BA6',
                R: 24,
                G: 107,
                B: 166
            },
            Info: {
                HEX: '#55BBB4',
                R: 85,
                G: 187,
                B: 180
            }
        }
    },
    Virtual: {
        Img: {
            Logo: {
                500500: {
                    cb: 'Logos/Virtual/500500cb.png',
                    ct: 'Logos/Virtual/500500ct.png',
                    bc1: 'Logos/Virtual/500500bc1.png',
                    bt: 'Logos/Virtual/500500bt.png'
                },
                700350: {
                    cb: 'Logos/Virtual/700350cb.png',
                    ct: 'Logos/Virtual/700350ct.png',
                    bc1: 'Logos/Virtual/700350bc1.png',
                    bt: 'Logos/Virtual/700350bt.png'
                },
                favicon: 'Logos/Virtual/favicon.png',
            },
            Variante: {
                1: ''
            }
        },
        Color: {
            Primary: {
                HEX: '#151F46',
                R: 21,
                G: 31,
                B: 70
            },
            Secondary: {
                HEX: '#A13A8D',
                R: 161,
                G: 58,
                B: 141
            },
            Info: {
                HEX: '#00BFB5',
                R: 0,
                G: 191,
                B: 181
            }
        }
    }
}


export const Logo = (props: any) => {
    return (<img src={`${Proveedor.Aws}${Organizations[props.Marca].Img.Logo[props.Size][props.Type]}`} className={props.className} style={props.sx}></img>)
}


export const ImgbyProveedor = (props: any) => {
    return (<img src={`${props.Prov}${props.Link}`} className={props.className} style={props.sx}></img>)
}


export const ImgbyLink = (props: any) => {
    return (<img src={`${props.Link}`} className={props.className} style={props.sx}></img>)
}


export const getPrimaryColorHEX: string = Organizations[Marca].Color.Primary.HEX

export const getSecondaryColorHEX: string = Organizations[Marca].Color.Secondary.HEX

export const getInfoColorHEX: string = Organizations[Marca].Color.Info.HEX

export function getLinkImg(Size: string, Type: string) { return Proveedor.Aws + Organizations[Marca].Img.Logo[Size][Type]; }
