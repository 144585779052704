// Producción
export const API_URL = 'https://evaapi.educem.mx/api'; 

//TEST
//export const API_URL = 'https://evaapi.educem.mx/api';

//LOCAL
//export const API_URL = 'http://localhost:9046/api';  




export const API_AP = 'https://educemap.educem.mx/user/me';
export const FIRE_URL = 'https://serviciosweb.institutofirenze.edu.mx';
export const SC_KEY = '979E7FAEBE46C439'
export const TOKEN_KEY = "token";

export const URL_EDUCEM = 'https://constanciasdigitales.educem.mx';  
export const EMAIL_EDUCEM = 'servicios.escolares@educem.mx';  



export const URL_FIRENZE = 'http://constanciasdigitales.firenze.edu.mx';   
export const EMAIL_FIRENZE = 'servicios.escolares@institutofirenze.edu.mx';  

