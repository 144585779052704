import { API_URL } from "../../constants";
export const GET_COSNTACIA = (
    folio: number,matricula:string
  ) => {
   return `${API_URL}/Constanciadigital/ByFolioAndMatricula?folio=${folio}&matricula=${matricula}`;
  };

  export const GET_MARCA = (
    folio: number,
  ) => {
   return `${API_URL}/Constanciadigital/marca?folio=${folio}`;
  };