import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Logo, getPrimaryColorHEX, getSecondaryColorHEX } from '../../config';
import StarsIcon from '@mui/icons-material/Stars';
import CardHeader from '@mui/material/CardHeader';
import { AppBar, Divider, Grid, Icon, Toolbar } from '@mui/material';
import GppGoodIcon from '@mui/icons-material/GppGood';
import format from 'date-fns/format';
import { es } from 'date-fns/locale';
interface Props {
  datos?: any
  marca?: any
}
function ConstanciaView({ datos, marca

}: Props) {

  const [data, setdata] = useState<null | any>(datos ? datos : null);
  const [dateF, setDateF] = useState(datos ? format(new Date(datos.fecharevision), "dd 'de' MMMM 'de' yyyy", { locale: es }) : "");




  return (<>
    {!datos ? "error" :
      <>

        <AppBar position="static">
          <Container maxWidth="xl"
            sx={{
              backgroundColor: marca !== "" ? getPrimaryColorHEX(marca) : "#233878",

            }}>
            <Toolbar
              disableGutters>
              {/* <GppGoodIcon sx={{ color: marca !== "" ? getSecondaryColorHEX(marca) : "#233878", fontSize: 50 }} /> */}
              <GppGoodIcon sx={{ color: "white", fontSize: 50 }} />


            </Toolbar>
          </Container>
        </AppBar>
        <Container >

          <Card className="text-center" id="EDUCEM">
            <CardContent>
              <Grid container

              >
                <Grid item md={10}>
                  <Typography variant="h5" component="div"
                  >
                    Esta constancia digital es válida
                  </Typography>
                  <Typography >
                    Si la información del documento coincide con el de esta página quiere decir que fue emitido por:
                  </Typography>
                </Grid>
                <Grid item md={12}>
                  <Grid container spacing={1}
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Grid item >
                      <Logo
                        sx={{ width: "150px", }}
                        Marca={marca}
                        Type="ct"
                        Size="700350"
                      ></Logo>

                    </Grid>
                    <br />
                    <Grid item >
                      <Typography style={{
                        color: marca !== "" ? getPrimaryColorHEX(marca) : "#233878",
                        wordBreak: "break-word"
                      }}>
                        {data?.denominacion_oficial}
                      </Typography>
                    </Grid>

                  </Grid>





                </Grid>
              </Grid>

            </CardContent>
            <Divider></Divider>

            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8}>

                  <Typography >
                    <strong>Folio:</strong> {data?.folio}
                  </Typography>
                  <Typography >
                    <strong>Matrícula:</strong> {data?.matricula}
                  </Typography>
                  <Typography  >
                    <strong>Alumno:</strong> {data?.alumno}
                  </Typography>
                  <Typography >
                    <strong>Plan de estudios:</strong> {data?.programa}
                  </Typography>
                </Grid>

                <Grid item md={4}>
                  <div style={{ textAlign: 'right' }}>
                    <Typography>
                      {data.ciudad}, {data.estado}
                    </Typography>
                    <Typography>
                      {dateF}
                    </Typography>
                  </div>
                </Grid>
              </Grid>

            </CardContent>
          </Card>

          <Card className="my-3">

            <CardContent>
              <Grid container spacing={1}>

                <Grid item marginLeft={1} marginRight={1}>
{/* 
                  <Typography variant="h6">Nombre del programa</Typography>
                  <Typography  >
                    {data?.programa}
                  </Typography> */}
                  {/* <Typography variant="h6">RVOE SEP</Typography>
                  <Typography className="form-control Info-Evaluacion">
                    {data?.rvoe}
                  </Typography> */}
                  {/* <Typography variant="h6">Clave del centro de trabajo</Typography>
                  <Typography className="form-control Info-Evaluacion">
                    {data?.cct}
                  </Typography>
                  <Typography variant="h6">Avance de escolaridad:</Typography>
                  <Typography className="form-control Info-Evaluacion">
                    {data?.grado} {data?.tipo_ciclo} de {data?.total_ciclos_programa}
                  </Typography> */}
                </Grid>
                <Grid item marginLeft={1} marginRight={1}>

                  {/* <Typography variant="h6">Estatus</Typography>
                  <Typography className="form-control Info-Evaluacion" >
                    {data?.estatus}
                  </Typography>
                  <Typography variant="h6">Ciclo escolar</Typography>
                  <Typography className="form-control Info-Evaluacion">
                    {data?.ciclo_escolar}
                  </Typography>
                  <Typography variant="h6">Periodo escolar</Typography>
                  <Typography className="form-control Info-Evaluacion">
                    {data?.fecha_inicio}-{data?.fecha_termino}
                  </Typography>
                  <Typography variant="h6">Periodo vacacional</Typography>
                  <Typography className="form-control Info-Evaluacion">
                    {data?.periodo_vacacional_1_inicio}-{data?.perdiodo_vacacional_1_termino}
                  </Typography>
                  <Typography className="form-control Info-Evaluacion">
                    {data?.periodo_vacacional_2_inicio}-{data?.perdiodo_vacacional_2_termino}
                  </Typography> */}
                </Grid>
              </Grid>
            </CardContent>

            <Divider></Divider>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item marginLeft={1} marginRight={1} xs={12} md={9}>
                  <Typography variant="h6">Nombre y cargo del firmante</Typography>
                  <Typography className="form-control Info-Evaluacion">
                    {data?.nombrecsd}
                  </Typography>
                  <Typography variant='caption'>
                    Número de serie del certificado CSD: <small> {data?.noseriecsd}</small>
                  </Typography>
                  <br />
                  <div style={{ width: "100%", wordWrap: "break-word" }}>
                    <Typography variant='caption'>
                      Firma digital: <br />
                      <small>
                        {data?.sellocsd}
                        {/* {data?.sellocsd?.split('+').map((line:any, index:any) => (
                          <React.Fragment key={index}>
                            {line}+
                            <br />
                          </React.Fragment>
                        ))} */}
                      </small>
                    </Typography></div>

                </Grid>
              </Grid>
            </CardContent>
            <Divider></Divider>
            <CardContent>
              <Typography variant='body2'>
                Cualquier inconsistencia en el documento o aclaraciones, favor de reportarlo adjuntando imagen completa del documento digital a través del correo
              </Typography>
              <Typography>
                {marca === "educem"
                  ? <a href="mailto:servicios.escolares@educem.mx">servicios.escolares@educem.mx</a>
                  : <a href="mailto:servicios.escolares@institutofirenze.edu.mx">servicios.escolares@institutofirenze.edu.mx</a>}
              </Typography>
            </CardContent>
          </Card>

        </Container></>
    }
  </>);
}


export default ConstanciaView;