import { useParams } from 'react-router-dom';
import { SC_KEY, TOKEN_KEY } from './constants';

export default function RedirectToApp() {
    const { id } = useParams();
    var CryptoJS = require("crypto-js");

    var bytes = CryptoJS.AES.decrypt(id.replace(/Por21Ld/g, '/'), SC_KEY);
    var idDecrypt = bytes.toString(CryptoJS.enc.Utf8);
    const arr = idDecrypt.split('|'); 
    
    var bytesAP = CryptoJS.AES.decrypt(localStorage.getItem('React.LC.KY.AP'), SC_KEY);
    var userAP = JSON.parse(bytesAP.toString(CryptoJS.enc.Utf8));
    userAP.scopes.map((scope) => {
        if (scope.scopeid === arr[0]) {
            window.location.href = `${scope.scopepath}?id=${localStorage.getItem(TOKEN_KEY)}`;
        }
    });
}
