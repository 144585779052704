import React, { useEffect, useState } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Dashboard from './pages/dashboard';
import Page404 from './pages/PageNotFound';
import Public from './pages/Public';
import { LinearProgress } from '@mui/material';
import { useAuthState } from './context/authContext';
import { GET_USER_LOGIN_ENDPOINT } from './utils/endpoints/ap/ApEndpoints';
import { GET_STUDENT_INFO_BY_EMAIL_ENDPOINT } from './utils/endpoints/certitulos/CertitulosEndpoints';
import { SC_KEY, TOKEN_KEY } from './utils/constants';
import MasterLogin from './pages/Login/MasterLogin';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import RedirectToApp from './utils/RedirectToApp';
import reportWebVitals from './reportWebVitals';
import { EvaluatedTeachers, EvaluationContext, StudentContext, TeachersContext, TeacherContext, TeacherAssignatures } from "./context/GeneralContexts";
import ErrorGeneral from './components/errors/ErrorGeneral';
import { Organizations, Proveedor } from './components/configuration/config';

const removeToken = () => {
    localStorage.removeItem(TOKEN_KEY);
}


const RenderAppFetchData = () => {

    const [dataAP, getDataAP] = useState();
    const [dataMS, getDataMS] = useState();
    const [isError, setIsError] = useState(false);
    const user = useAuthState();
    var CryptoJS = require("crypto-js");
    var access = false;
    var marcaUrl = "";
    let auxUrl = window.location.origin.split(".")[1];

    switch (auxUrl) {
        case "educem":
            marcaUrl = "educem.mx"
            break;

        case "tecmas":
            marcaUrl = "tecmas.mx"
            break;

        case "institutofirenze":
            marcaUrl = "institutofirenze.edu.mx";
            break;

        default:
            break;
    }

    const domain = user.email.split("@")[1]

    const favicon = document.getElementById('favicon');
    favicon.href = Proveedor['Aws'] + Organizations[domain.includes("educem") ? "Educem" : domain.includes("tecmas") ? "Tecmas" : "FirenzeS"].Img.Logo.favicon;


    const returnUser = () => { window.location.href = `https://sam.${marcaUrl ? marcaUrl : "educem.mx"}`; }

    useEffect(() => {
        if (!dataAP) {
            fetch(GET_USER_LOGIN_ENDPOINT, { headers: new Headers({ 'Authorization': `Bearer ${user.token}` }) }).then((res) => res.json()).then((data) => getDataAP(data)).catch(err => setIsError(true));
        }
      //  if (!dataMS) {
        //    fetch(GET_STUDENT_INFO_BY_EMAIL_ENDPOINT(user.email), { headers: new Headers({ 'Authorization': `Bearer ${user.token}` }) }).then((res) => res.json()).then((data) => {getDataMS(data); setcontextTeacherAssignatures(data) }).catch(err => setIsError(true));
       // }

    }, [dataAP, dataMS]);

    if (isError) {

    } else {
        if (!dataAP ) {
        } else {
            dataAP.scopes.map((scope) => {
               // console.log(scope)
                if (scope.scopeid === 3 || scope.scopeid === 18 || scope.scopeid === 20 || scope.scopeid === 21 || scope.scopeid === 22) {
                    access = true;
                }
            });


            if (access) {
                if (access) {
                    if (localStorage.getItem('React.LC.KY.AP') === null) {
                        var dataAp_encrypt = CryptoJS.AES.encrypt(JSON.stringify(dataAP), SC_KEY).toString();
                        localStorage.setItem('React.LC.KY.AP', dataAp_encrypt)
                    }

                   /* if (localStorage.getItem('React.LC.KY.MS') === null) {
                        var dataMS_encrypt = CryptoJS.AES.encrypt(JSON.stringify(dataMS), SC_KEY).toString();
                        localStorage.setItem('React.LC.KY.MS', dataMS_encrypt);
                    }*/
                } else {
                    removeToken();
                    returnUser();
                }

            } else {
                removeToken();
                returnUser();
            }
        }
    }


    const [context, setContexT] = useState({});
    const [contextStudent, setContextS] = useState({});
    const [contextEvaluation, setContextEv] = useState({});
    const [contextEvaluatedTeachers, setcontextEvaluatedTeachers] = useState({})
    const [contextTeachers, setcontextTeachers] = useState({})
    const [contextTeacherAssignatures, setcontextTeacherAssignatures] = useState({})

    return (
        <>
            {(isError) ?
                <ErrorGeneral></ErrorGeneral> : (!dataAP ) ?
                    <LinearProgress></LinearProgress>
                    :
                    <TeacherAssignatures.Provider value={[contextTeacherAssignatures, setcontextTeacherAssignatures]}>
                        <StudentContext.Provider value={[contextStudent, setContextS]}>
                            <EvaluationContext.Provider value={[contextEvaluation, setContextEv]}>
                                <TeacherContext.Provider value={[context, setContexT]}>
                                    <TeachersContext.Provider value={[contextTeachers, setcontextTeachers]}>
                                        <EvaluatedTeachers.Provider value={[contextEvaluatedTeachers, setcontextEvaluatedTeachers]}>
                                            <BrowserRouter>
                                                <Routes>
                                                    <Route path="/masterlogin/" element={<MasterLogin />}></Route>
                                                    <Route path="/getAppCon/:id" element={<RedirectToApp />}></Route>
                                                    <Route path="/home/*" element={<Dashboard />}></Route>
                                                    <Route path="/evaluations/*" element={<Dashboard />}></Route>
                                                    <Route path="/home" element={<Navigate path="/" to="/home/home" />}> </Route>
                                                    <Route path="/" element={<Navigate path="/" to="/home/home" />}> </Route>
                                                    <Route path="/public" element={<Public />}> </Route>
                                                    <Route path="/*" element={<Page404 />}> </Route>
                                                </Routes>
                                            </BrowserRouter>
                                        </EvaluatedTeachers.Provider>
                                    </TeachersContext.Provider>
                                </TeacherContext.Provider>
                            </EvaluationContext.Provider>
                        </StudentContext.Provider>
                    </TeacherAssignatures.Provider>
            }
        </>

    )
}

export default RenderAppFetchData;

serviceWorkerRegistration.register();

reportWebVitals();
