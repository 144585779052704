import React, { useEffect, useState } from 'react'
import Constancia from '../../components/Constacias/Constancia';

import Backdrop from '@mui/material/Backdrop';
import { CircularProgress } from '@mui/material';
import { useQuery } from 'react-query';
import { getMarca } from '../../services/Sai/SaiServices';
import { URL_EDUCEM, URL_FIRENZE } from '../../utils/constants';

function Public() {

  const [open, setOpen] = useState(true);
  const handleClose = () => {
    //setOpen(false);
  };

  const [url, setURL] = useState("");
  const [marcaUrl, setMarcaUrl] = useState("");
  const [path, setPath] = useState("");
  const [search, setSearch] = useState("");
  const [matricula, setMatricula] = useState("");
  const [folio, setFolio] = useState<number>(0);
  const [enabled, setEnabled] = useState(false);
  const [enabledMarca, setEnabledMarca] = useState(false);
  const [porqr, setPorqr] = useState(false);

  useEffect(() => {
    // Obtener la URL actual
    const currentURL = window.location.href;
    const url = new URL(currentURL);
    const matricula = url.searchParams.get('matricula');
    const folioParam = url.searchParams.get('Folio');
    const baseURL = window.location.origin;
    const path = window.location.pathname;
    const search = window.location.search;


    console.log("baseURL:" + baseURL)
    console.log("path:" + path)
    console.log("search:" + search)


    if (window.location.origin.includes("firenze")) {
      setMarcaUrl("institutofirenze")
    } else {
      setMarcaUrl("educem")
      //setMarcaUrl("institutofirenze")
    }
    if (window.location.origin.includes("serviciosweb")) {
    //if (window.location.origin.includes("local")) {
      if (folioParam) {
        setEnabledMarca(true)
      }
    }else{
      setPorqr(true)
      setEnabled(false)
      setOpen(false)
    }
    setPath(path);
    setSearch(search);
    setMatricula(matricula ? matricula : "")
    setFolio(folioParam ? parseInt(folioParam, 10) : 0)
    setURL(baseURL)
  }, []);


  const { isLoading, isFetching, isError } = useQuery(["getmarca"], () =>
    getMarca(folio), {
    enabled: enabledMarca,
    onSuccess(data: any) {
      if (data.data.includes("educem")) {
        console.log("Redirigiendo a:"+URL_EDUCEM + path + search)
        //window.location.href = URL_EDUCEM + path + search;
        window.location.assign(URL_EDUCEM + path + search);


      } else if (data.data.includes("fire")) {        
        console.log("Redirigiendo a:"+URL_FIRENZE + path + search)
        //window.location.href = URL_FIRENZE + path + search;
        window.location.assign(URL_FIRENZE + path + search);

      }
      setEnabledMarca(false)
    },
    onError(err: any) {
      console.log("err/////////////////")
      console.log(err)
    }
  });




  return (
    <>

      <Constancia
        folio={folio}
        setFolio={setFolio}
        matricula={matricula}
        setMatricula={setMatricula}
        enabled={enabled}
        setEnabled={setEnabled}
        search={search}
        path={path}
        setOpen={setOpen}
        marca={marcaUrl}
        setMarca={setMarcaUrl}
        porqr={porqr}
        setPorqr={setPorqr}
      ></Constancia>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>

  )
}
export default Public;