import { Link } from "react-router-dom";
import React from 'react'

function Page404() {
   return (
      <div>
         <h1>404 Not found</h1>
         <p>Página no encontrada</p>
         {/* <Link to="/"> Ir a Evaluación Docente Educem</Link> */}
      </div>
   )
}
export default Page404;